import Intro from "../components/intro";

function Home(){
  return(
    <>
     <Intro />
    </>
  );
}

export default Home;