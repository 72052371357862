import PropTypes from 'prop-types';
import { Text } from '@chakra-ui/react';

const H1 = (props) => <Text as="h1" textStyle="h1" {...props} />;
const H2 = (props) => <Text as="h2" textStyle="h2" {...props} />;
const H3 = (props) => <Text as="h3" textStyle="h3" {...props} />;
const H4 = (props) => <Text as="h4" textStyle="h4" {...props} />;

H1.propTypes = H2.propTypes = H3.propTypes = H4.propTypes = {
    styles: PropTypes.object,
};

export { H1, H2, H3, H4 };