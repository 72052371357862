const textStyles = {
    h1: {
        mt:         [3, 3, 6],
        mb:         [3, 3, 6],
        fontSize:   ["2xl", "3xl", "4xl"], // 4, 4, 5
        textAlign:  'center',
        lineHeight: 1.4,
        fontWeight: "bold",
      },
      h2: {
        mt:         4,
        mb:         3,
        fontSize:   ["2xl", "2xl", "3xl"],
        fontWeight: "bold",
        textAlign:  'center',
      },
      h3: {
        mt:         3,
        mb:         2,
        fontSize:   ["xl", "xl", "2xl"],
        fontWeight: "bold",
        lineHeight: "shorter",
      },
      h4: {
        mt:         3,
        mb:         2,
        fontSize:   ["lg", "lg", "xl"],
        fontWeight: "medium",
      }
}

export default textStyles