const styles = {
    global: {
        "html, body": {
          maxWidth: "100vw",
          backgroundColor: "#282c34",
          //background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(50,50,25,1) 28%, rgba(255,132,0,1) 100%)",
        },
        button: {
          h: "auto",
        },
        a: {
          _hover: {
            textDecoration: "underline",
          },
        },
    }
}

export default styles