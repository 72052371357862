import { Box, Button, Image } from '@chakra-ui/react';
import icon from '../assets/icon.jpg';
import { useEffect, useState } from 'react';
import getNumberKamilReads from '../api/getNumberKamilReads';
import { H1 } from './styles/TypoComponents'
import getNumberJuzuReads from '../api/getNumberJuzuReads';
import { incrementNumberJuzuReads } from '../api/incrementNumberJuzuReads';


function Intro() {

  const [kamilReads, setKamilReads] = useState([]);
  const[ajizaReads, setAjizaReads] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let dataKamil = await getNumberKamilReads();
      let dataAjiza = await getNumberJuzuReads();
      setKamilReads(dataKamil);
      setAjizaReads(dataAjiza);
    }
    fetchData();

  }, []);

  const handleClick =(async () => {
    let response = await incrementNumberJuzuReads(1);
  })

  return (
    <Box
      display="flex"
      flexDirection={"column"}
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Image src={icon} alt="logo" />
      <H1 mt={'10px'} color={"white"} > {kamilReads} </H1>
      <H1 mt={'10px'} color={"white"} > {ajizaReads} </H1>

      <Button mt={'10px'}
        onClick={handleClick}
      >
        marquer comme lu
      </Button>
    </Box>
  );
}

export default Intro;