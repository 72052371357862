import { ChakraBaseProvider } from '@chakra-ui/react';
import './App.css';
import Router from './router/Router';
import theme from './theme';

function App() {

  console.log(theme);

  return (
    <ChakraBaseProvider theme={theme} >
      <Router />
    </ChakraBaseProvider>
  );
}

export default App;
