import { extendTheme } from "@chakra-ui/react";

//foundational style overrides
import breakpoints from "./foundations/breakpoints";
import textStyles from "./foundations/textStyles";
import styles from "./foundations/styles";

//component style overrides
import Container from "./components/container";

const overrides = {
    breakpoints,
    textStyles,
    styles,
    components: {
        Container,
    },
};

export default extendTheme(overrides);