import { defineStyleConfig } from '@chakra-ui/react'


const mainContainerStyle = {
  minHeight:     '100vh',
  width:         '100%',
  pt:            ['95px', '125px', '150px'],
  flex:          1,
  display:       'flex',
  flexDirection: 'column',
  alignItems:    'center',
  overflowWrap:  'break-word',
}


const container = defineStyleConfig({
    baseStyle: {
        maxW: 'full',
        px: 0,
    },
    variants: {
      content : {
        maxW: '1400px',
      },
      main : mainContainerStyle,
      page: {
        flex:          1,
        py:            0,
        width:         '100%',
        flexDirection: 'column',
      },
      header: {
        bg:             'grey',
        color:          'white',
        position:       'fixed',
        top:            0,
        left:           0,
        right:          0,
        zIndex:         'overlay',
        maxW: "100%",
        width:'100%',
        minHeight:      ['95px', '125px', '150px'],
        height:      ['95px', '125px', '150px'],
        // flexDirection:  'column', // + Display flex ?
        // justifyContent: 'center',
        padding:        8,
      },
    },
})

export default container